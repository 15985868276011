<template>
  <b-modal
    v-if="active"
    id="add-vehicle-registration-modal"
    size="lg"
    centered
    hide-footer
    @show="getRegistrationProducts"
    @hide="hide"
  >
    <template slot="modal-title">
      <h3 style="margin: 0; padding: 0">
        {{ title }}
      </h3>
    </template>
    <div v-if="!selectedCompany">
      <h3>Register Your Vehicle with Your Montana LLC</h3>
      <p>Looking to register another vehicle under your Montana LLC? You've come to the right place!</p>
      <h5>How It Works</h5>
      <ol>
        <li>Place your vehicle registration order.</li>
        <li>After checkout, you'll receive an email with instructions on what documents to ship to us for filing with the state.</li>
        <li>Once the state fulfills your order, we will ship your Montana license plates to the mailing address in your account.</li>
      </ol>
      <h5>Convenient Automatic Renewals</h5>
      <p>For your convenience, after your vehicle registration is complete, we will automatically enroll your vehicle in annual registration renewals if required.</p>
      <h5>
        Select a company for the vehicle to be registered to
      </h5>
      <company-select
        class="mb-3"
        :companies="MTCompanies"
        @input="companySelected"
      />
    </div>
    <div v-else>
      <div v-if="!serviceToAdd">
        <div class="button-container">
          <b-link
            class="py-0"
            variant="link"
            aria-label="Select a Different Company Link"
            @click="selectedCompany = null"
          >
            <fa-icon icon="chevron-left" />
            Back / Select a Different Company
          </b-link>
        </div>
        <h3>
          Select your vehicle type
        </h3>
        <selection-cards
          :items="vehicleFilingProducts()"
          @chosen="selectedService"
        />
      </div>
      <div v-if="serviceToAdd && !confirmOrder">
        <div v-if="!continueToCheckout">
          <div class="button-container">
            <b-link
              class="py-0"
              variant="link"
              aria-label="Select a Different Product link"
              @click="selectDifferentProduct"
            >
              <fa-icon icon="chevron-left" />
              Back / Select a Different Product
            </b-link>
          </div>
          <vehicle-registration-form-fields
            :selected-registration="serviceToAdd"
            :old-vehicle="vehicle"
            :loaded="loaded"
            @new-vehicle="newVehicle"
          />
        </div>
        <div v-else>
          <div class="button-container">
            <b-link
              class="py-0"
              variant="link"
              aria-label="Modify Vehicle link"
              @click="modifyVehicle"
            >
              <fa-icon icon="chevron-left" />
              Back / Modify Vehicle
            </b-link>
          </div>
          <div>
            <h3>Checkout</h3>
            <p>{{ vehicle_information }}</p>
          </div>
          <div>
            <payment-method-options :payment-method-errors="paymentMethodErrors" />
            <cart
              :processing-checkout="processingCheckout"
              button-text="Checkout"
            />
          </div>
        </div>
      </div>
      <div v-if="confirmOrder">
        <div class="button-container">
          <b-link
            class="py-0"
            variant="link"
            aria-label="Add Another Vehicle link"
            @click="resetForm"
          >
            <fa-icon icon="chevron-left" />
            Back / Add Another Vehicle
          </b-link>
        </div>
        <vehicle-order-confirmation
          :invoice="invoice"
        />
      </div>
    </div>

    <ct-centered-spinner v-if="!loaded" />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '../shared/CtCenteredSpinner'
import CompanySelect from '@/components/CompanySelect.vue'
import SelectionCards from '@/components/shared/SelectionCards.vue'
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions.vue'
import Cart from '@/components/HireUs/Cart.vue'
import VehicleRegistrationFormFields from '@/components/Vehicles/VehicleRegistrationFormFields.vue'
import VehicleOrderConfirmation from '@/components/Vehicles/VehicleOrderConfirmation.vue'

export default {
  name: 'AddVehicleRegistrationModal',
  components: {
    VehicleOrderConfirmation,
    VehicleRegistrationFormFields,
    Cart,
    PaymentMethodOptions,
    SelectionCards,
    CompanySelect,
    CtCenteredSpinner,
  },
  data() {
    return {
      active: true,
      selectedCompany: null,
      serviceToAdd: null,
      loaded: true,
      processingCheckout: false,
      paymentMethodErrors: null,
      vehicle: null,
      continueToCheckout: false,
      confirmOrder: false,
      invoice: null,
      adminInvoiceOnly: false,
    }
  },
  computed: {
    ...mapGetters('companies', ['companies', 'currentCompany']),
    ...mapGetters('vehicleRegistration', ['vehicleRegistrationProducts', 'vehicleEntityTypes']),
    ...mapGetters('checkout', [
      'cartEmpty',
      'cartItems',
    ]),

    MTCompanies() {
      const allowableEntityTypes = this.vehicleEntityTypes
      return this.companies.filter(company => company.jurisdictions.includes("Montana") && allowableEntityTypes?.includes(company.entity_type))
    },
    title() {
      return !this.selectedCompany ? 'Select a Company' : `Add Vehicle For ${this.currentCompany.name}`
    },
    vehicle_information() {
      return `${this.vehicle.model_year} ${this.vehicle.make} ${this.vehicle.model} VIN - ${this.vehicle.vehicle_identification_number}`
    },
  },
  destroyed() {
    this.$root.$off('cart-next-page')
    this.$root.$off('checkout-without-payment')
  },
  methods: {
    ...mapActions('companies', ['setCurrentCompany']),
    ...mapActions('vehicleRegistration', ['loadVehicleRegistrationProducts', 'loadVehicles']),
    ...mapActions('checkout', ['addToCart', 'setCartItemParentId', 'clearCart', 'checkout']),
    hide() {
      this.$root.$off('cart-next-page')
      this.$root.$off('checkout-without-payment')
    },
    selectDifferentProduct() {
      this.serviceToAdd = null
      this.vehicle = null
    },
    async getRegistrationProducts() {
      this.$root.$off('cart-next-page')
      this.$root.$on('cart-next-page', () => {
        this.proceedCheckout()
      })

      this.$root.$off('checkout-without-payment')
      this.$root.$on('checkout-without-payment', () => {
        this.adminInvoiceOnly = true
        this.proceedCheckout()
      })

      this.active = true
      await this.loadVehicleRegistrationProducts()
    },
    async companySelected(companyId) {
      this.loaded = false
      this.selectedCompany = null
      await this.setCurrentCompany({ id: companyId })
      this.selectedCompany = companyId
      this.loaded = true
      await this.clearCart()
    },
    vehicleFilingProducts() {
      let products = this.vehicleRegistrationProducts

      const orderedProducts = ['Car/Truck', 'Motor Home', 'Motorcycle/ATV']

      products = orderedProducts
        .map(name => products.find(product => product.name === name))
        .concat(
          products.filter(product => !orderedProducts.includes(product.name))
            .sort(product => product.name === 'Heavy Truck' ? 1 : -1)
        )

      products.forEach(product => {
        product.description = `$${product.price.toFixed(2)} + fees`
      })

      return products
    },
    selectedService(service) {
      this.serviceToAdd = service
    },
    async productAdded(product) {
      const simpleProductsToAdd = this.findItemsToAddToInvoice(this.vehicle)
      const vehicleFilingId = this.cartItems.find(cartItem => cartItem.product_id === product.id).id
      for (let simpleProduct of simpleProductsToAdd) {
        await this.addToCart(simpleProduct.fields)
        let cartItem = this.cartItems.find(cartItem => cartItem.product_id === simpleProduct.fields.id)
        cartItem.parentItemId = vehicleFilingId
        await this.setCartItemParentId(cartItem)
      }
    },
    findItemsToAddToInvoice(vehicle) {
      let products = []

      if (vehicle.add_to_invoice) {
        products.push(vehicle)
      }

      for (let key in vehicle) {
        if (typeof vehicle[key] === 'object' && vehicle[key] !== null) {
          products = products.concat(this.findItemsToAddToInvoice(vehicle[key]))
        }
      }

      return products
    },
    buildProductData() {
      // eslint-disable-next-line no-undef
      let vehicle = structuredClone(this.vehicle)
      const glossaryTerms = {
        "vehicle_identification_number": "vin___hin",
        "make": "vehicle_make",
        "vehicle_type": "vehicle_type",
        "model_year": "vehicle_year",
        "color": "vehicle_color",
        "model": "vehicle_model",
        "fuel_type": "vehicle_fuel_type",
        "current_mileage": "current_mileage",
        "registration_period": "registration_period",
        "msrp": "msrp",
        "rv_class": "rv_class",
        "weight": "weight_gvwr",
        "length": "length",
        "boat_propulsion": "boat_propulsion",
        "vessel_material": "vessel_material",
        "vessel_type": "vessel_type",
        "engine_displacement": "engine_displacement",
        "vehicle_subtype": "registration_type",
        "luxury_tax": "luxury_tax",
        "import_fee": "import_fee",
      }
      let data = { entity_type: this.currentCompany.entity_type }
      // Check Override Fields - These are setup in product metadata
      for (const key in vehicle) {
        if (typeof vehicle[key] === 'object' && vehicle[key] !== null) {
          if (vehicle[key].hasOwnProperty('field_override') && vehicle[key].hasOwnProperty('name_override')) {
            vehicle[vehicle[key].field_override] = vehicle[key].name_override
          }
          else {
            vehicle[key] = vehicle[key].name
          }
        }
      }
      // Convert vehicle key to glossary term
      for (const key in vehicle) {
        if (glossaryTerms.hasOwnProperty(key)) {
          data[glossaryTerms[key]] = (typeof vehicle[key] === 'object') ? vehicle[key].text : vehicle[key]
        }
      }
      return data
    },
    async modifyVehicle () {
      await this.clearCart()
      this.continueToCheckout = false
    },
    async newVehicle (vehicle) {
      this.vehicle = vehicle
      this.serviceToAdd.data = this.buildProductData()
      await this.addFilingToCart()
      this.continueToCheckout = true
    },
    async addFilingToCart() {
      this.loaded = false
      await this.addToCart({
        ...this.serviceToAdd,
        filingMethodId: this.serviceToAdd.filing_method.id,
      })
      await this.productAdded(this.serviceToAdd)
      this.loaded = true
    },
    async proceedCheckout() {
      this.processingCheckout = true
      const response = await this.checkout(this)
      this.processingCheckout = false
      if (response.invoice) {
        this.invoice = response.invoice
        this.confirmOrder = true
        await this.loadVehicles()
      }
      else {
        this.paymentMethodErrors = response?.errors
      }
    },
    resetForm() {
      this.active = true
      this.selectedCompany = null
      this.serviceToAdd = null
      this.loaded = true
      this.processingCheckout = false
      this.vehicle = null
      this.continueToCheckout = false
      this.confirmOrder = false
      this.invoice = null
      this.adminInvoiceOnly = false
    },
  },
}
</script>

<style lang="scss"  scoped>
  .centered {
    text-align: center
  }
</style>
