<template>
  <div>
    <h5 class="padding-top-1-5rem">
      Choose your sponsor plate design
    </h5>
    <p>
      <em>
        Note: Processing times can take several months depending on current stock of the requested plate.
      </em>
    </p>
    <multi-select
      v-model="selectedPlate"
      :options="vehicleOrgPlates"
      label="plate_name"
      :allow-empty="false"
      :searchable="false"
      deselect-label=""
      select-label=""
      placeholder="Select one"
      class="multi-select-dropdown padding-bottom-1-5rem"
      @select="updateVehicleOrgPlate"
    >
      <template #option="{ option }">
        <div class="option-item">
          <b-img :src="option.image" alt="Plate Image" class="option-image" />
          <span class="option-label">
            {{ option.plate_name }}
          </span>
        </div>
      </template>
    </multi-select>
    <div v-if="selectedPlate" class="plate-support">
      <h4 class="plate-title">
        What does this plate support?
      </h4>
      <div class="plate-image-wrapper">
        <b-img
          v-if="selectedPlate.image"
          :src="selectedPlate.image"
          alt="Selected Plate Image"
          class="plate-image"
          @click="enlargeImage(selectedPlate)"
        />
      </div>
      <ul class="plate-details list-unstyled">
        <li>
          Annual Fee {{ parseFloat(selectedPlate.price) | dollars }}
          <fa-icon
            v-b-popover.hover.right="'This fee is included with your initial purchase, but will be charged at annual renewal'"
            class="question-circle pl-1 help-text"
            icon="question-circle"
            size="1x"
          />
        </li>
        <li>
          {{ selectedPlate.description }}
        </li>
      </ul>

      <b-modal v-model="isImageModalVisible" size="lg" :title="selectedPlate.plate_name" hide-footer>
        <b-img
          :src="modalImage"
          alt="Enlarged Plate Image"
          class="img-fluid enlarged-plate"
        />
        <ct-centered-spinner v-if="modalImage === selectedPlate.image"
        />
      </b-modal>
    </div>
    <filing-checkout
      v-if="selectedPlate"
      :company="currentCompany"
      :product="serviceToAdd"
      :hide-header="true"
      @product-added="productAdded"
    />
  </div>
</template>
<script>
import MultiSelect from "vue-multiselect"
import FilingCheckout from "@/components/HireUs/FilingCheckout.vue"
import { mapGetters } from 'vuex'
import { dollars } from '@/filters'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'

export default {
  name: "SelectSponsorPlate",
  components: {
    CtCenteredSpinner,
    MultiSelect,
    FilingCheckout,
  },
  filters: {
    dollars,
  },
  props: {
    serviceToAdd: Object,
  },
  data() {
    return {
      selectedPlate: null,
      isImageModalVisible: false,
      modalImage: null,
    }
  },
  computed: {
    ...mapGetters('companies', ['currentCompany']),
    ...mapGetters('vehicleRegistration', ['vehicleOrgPlates', 'sponsorPlateImageBaseUrl']),
  },
  methods: {
    updateVehicleOrgPlate(plate) {
      this.loadFullImage(plate)
      this.$emit('selected-plate', plate)
    },
    productAdded(product) {
      this.$emit('product-added', product)
    },
    enlargeImage(plate) {
      this.modalImage = plate.image
      this.isImageModalVisible = true
      this.loadFullImage(plate)
    },
    loadFullImage(plate) {
      const imageUrl = `${this.sponsorPlateImageBaseUrl + plate.id}`
      const img = new Image()
      img.onload = () => {
        this.modalImage = imageUrl
      }
      img.src = imageUrl
    },
  },
}


</script>

<style lang="scss" scoped>
.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.option-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.option-image {
  width: 100px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.option-label {
  font-size: 1rem;
  color: #333;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex: 1;
}

.multi-select-dropdown {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.plate-support {
  margin: 2rem 0;
  font-family: Arial, sans-serif;
}

.plate-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.plate-image-wrapper {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.plate-image {
  max-width: 200px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.plate-image:hover {
  transform: scale(1.1);
}

.plate-details {
  padding: 0;
  margin: 1rem 0;
}

.enlarged-plate {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.plate-details li {
  margin-bottom: 0.5rem;
  color: #555;
  line-height: 1.5;
}

.question-circle {
  margin-left: 0.5rem;
  color: #007bff;
  cursor: pointer;
}

.help-text {
  font-size: 0.9rem;
}
</style>
