<template>
  <div class="confirmation-header-container">
    <div class="confirmation-content d-flex justify-content-between align-items-center">
      <div class="confirmation-text">
        <h4 class="confirmation-number">
          Thank you for your order! You can track your order on the 'Filing Orders' page from the home page.
        </h4>
        <h4 class="confirmation-number">
          Your order confirmation number is <span>{{ '#' + invoiceNumber }}</span>
        </h4>
        <p>
          The following items were included in your order:
        </p>
        <ul>
          <li v-for="item in invoice.invoice_line_items" :key="item.id">
            <p>
              {{ item.product.name }} for {{ item.sub_description_lines[0] }}
            </p>
          </li>
        </ul>
      </div>
      <picture>
        <source
          :srcset="require('@images/illustrations/products/car-teal-500px.webp')"
          type="image/webp"
        >
        <img
          class="confirmation-image"
          :src="require('@images/illustrations/products/car-teal-500px.png')"
          alt="Car illustration"
        >
      </picture>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VehicleAdditionalServiceConfirmation',
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    invoiceNumber() {
      return this.invoice.invoice_number
    },
  },
}
</script>

<style lang="scss" scoped>
.confirmation-header-container {
  padding: 1.25em;
  background-color: #f9f9f9;
  font-size: 90%;
}

.confirmation-title {
  text-align: center;
  margin-bottom: 1.25em;
  color: #003366;
}

.confirmation-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.25em;
}

.confirmation-image {
  max-width: 150px;
  margin-left: 1.25em;
}

.confirmation-text {
  flex: 1;
}

.confirmation-number {
  margin: .6em 0;
}

p {
  margin: .6em 0;
  color: #555;
}

h3 {
  margin-bottom: .6em;
  color: #003366;
  font-size: 1.5rem;
}

ul {
  list-style-type: "- ";
  margin-left: .3em;
  padding-left: 1.25em;
}

li {
  margin-bottom: .6em;
}
@media (max-width: 992px) {
  .confirmation-image {
    display: none;
  }

  .confirmation-content {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
