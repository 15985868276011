var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h5", { staticClass: "padding-top-1-5rem" }, [
        _vm._v("\n    Choose your sponsor plate design\n  "),
      ]),
      _vm._m(0),
      _c("multi-select", {
        staticClass: "multi-select-dropdown padding-bottom-1-5rem",
        attrs: {
          options: _vm.vehicleOrgPlates,
          label: "plate_name",
          "allow-empty": false,
          searchable: false,
          "deselect-label": "",
          "select-label": "",
          placeholder: "Select one",
        },
        on: { select: _vm.updateVehicleOrgPlate },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function ({ option }) {
              return [
                _c(
                  "div",
                  { staticClass: "option-item" },
                  [
                    _c("b-img", {
                      staticClass: "option-image",
                      attrs: { src: option.image, alt: "Plate Image" },
                    }),
                    _c("span", { staticClass: "option-label" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(option.plate_name) +
                          "\n        "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedPlate,
          callback: function ($$v) {
            _vm.selectedPlate = $$v
          },
          expression: "selectedPlate",
        },
      }),
      _vm.selectedPlate
        ? _c(
            "div",
            { staticClass: "plate-support" },
            [
              _c("h4", { staticClass: "plate-title" }, [
                _vm._v("\n      What does this plate support?\n    "),
              ]),
              _c(
                "div",
                { staticClass: "plate-image-wrapper" },
                [
                  _vm.selectedPlate.image
                    ? _c("b-img", {
                        staticClass: "plate-image",
                        attrs: {
                          src: _vm.selectedPlate.image,
                          alt: "Selected Plate Image",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.enlargeImage(_vm.selectedPlate)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("ul", { staticClass: "plate-details list-unstyled" }, [
                _c(
                  "li",
                  [
                    _vm._v(
                      "\n        Annual Fee " +
                        _vm._s(
                          _vm._f("dollars")(parseFloat(_vm.selectedPlate.price))
                        ) +
                        "\n        "
                    ),
                    _c("fa-icon", {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.right",
                          value:
                            "This fee is included with your initial purchase, but will be charged at annual renewal",
                          expression:
                            "'This fee is included with your initial purchase, but will be charged at annual renewal'",
                          modifiers: { hover: true, right: true },
                        },
                      ],
                      staticClass: "question-circle pl-1 help-text",
                      attrs: { icon: "question-circle", size: "1x" },
                    }),
                  ],
                  1
                ),
                _c("li", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.selectedPlate.description) +
                      "\n      "
                  ),
                ]),
              ]),
              _c(
                "b-modal",
                {
                  attrs: {
                    size: "lg",
                    title: _vm.selectedPlate.plate_name,
                    "hide-footer": "",
                  },
                  model: {
                    value: _vm.isImageModalVisible,
                    callback: function ($$v) {
                      _vm.isImageModalVisible = $$v
                    },
                    expression: "isImageModalVisible",
                  },
                },
                [
                  _c("b-img", {
                    staticClass: "img-fluid enlarged-plate",
                    attrs: { src: _vm.modalImage, alt: "Enlarged Plate Image" },
                  }),
                  _vm.modalImage === _vm.selectedPlate.image
                    ? _c("ct-centered-spinner")
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedPlate
        ? _c("filing-checkout", {
            attrs: {
              company: _vm.currentCompany,
              product: _vm.serviceToAdd,
              "hide-header": true,
            },
            on: { "product-added": _vm.productAdded },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("em", [
        _vm._v(
          "\n      Note: Processing times can take several months depending on current stock of the requested plate.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }