<template>
  <div>
    <div>
      <h3>
        {{ selectedRegistration.name }}
      </h3>
    </div>
    <form @submit.prevent="handleSubmit">
      <validation-observer v-slot="{ invalid }">
        <div class="form-grid">
          <div v-for="(field, index) in fields" :key="index" class="form-item">
            <b-form-group
              horizontal
              :label="field.label + (field.required ? ' ' : '')"
            >
              <template v-slot:label>
                {{ field.label }}
                <span v-if="field.required" class="text-danger">*</span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                :rules="formRules(field)"
                :name="field.label"
              >
                <template v-if="field.type === 'number'">
                  <b-form-input
                    v-model="newVehicle[field.key]"
                    :min="field.min"
                    :max="field.max"
                    type="number"
                    step="1"
                    :state="!errors[0]"
                  />
                </template>
                <template v-if="field.type === 'number-lux'">
                  <b-form-input
                    v-model="newVehicle[field.key]"
                    :min="field.min"
                    :max="field.max"
                    type="number"
                    step="1"
                    :state="!errors[0]"
                    @input="determineFixedValues"
                  />
                </template>
                <template v-if="field.type === 'number-gvw'">
                  <b-form-input
                    v-model="newVehicle[field.key]"
                    :min="field.min"
                    :max="field.max"
                    type="number"
                    step="1"
                    :state="!errors[0]"
                    @input="addGVW"
                  />
                </template>
                <template v-if="field.type === 'text'">
                  <b-form-input
                    v-model="newVehicle[field.key]"
                    type="text"
                    :state="!errors[0]"
                  />
                </template>
                <template v-if="field.type === 'vin'">
                  <b-form-input
                    v-model="newVehicle[field.key]"
                    type="text"
                    :allow-empty="false"
                    :multiple="false"
                    :state="!errors[0]"
                    @input="retrieveVinInfo"
                  />
                </template>
                <template v-if="field.type === 'boolean' && newVehicle['registration_period'] !== 'PERMANENT'">
                  <b-form-checkbox
                    v-model="newVehicle[field.key]"
                    button-variant="success"
                    switch
                    size="lg"
                    :state="!errors[0]"
                  />
                </template>
                <template v-if="field.type === 'select'">
                  <multiselect
                    v-model="newVehicle[field.key]"
                    :options="field.values"
                    :allow-empty="false"
                    :multiple="false"
                    :disabled="field['disabled']"
                    deselect-label=""
                    select-label=""
                  />
                </template>
                <template v-if="field.type === 'select-fuel-add-ev-hybrid-tax'">
                  <multiselect
                    v-model="newVehicle[field.key]"
                    :options="field.values"
                    :allow-empty="false"
                    :multiple="false"
                    deselect-label=""
                    select-label=""
                    @input="determineEvHybridTax"
                  />
                </template>
                <template v-if="field.type === 'select-luxury_tax'">
                  <div>
                    <multiselect
                      v-model="newVehicle[field.key]"
                      :options="field.values"
                      label="text"
                      track-by="value"
                      :allow-empty="false"
                      :multiple="false"
                      deselect-label=""
                      select-label=""
                      disabled="disabled"
                    />
                  </div>
                </template>
                <template v-if="field.type === 'select-electric-hybrid-tax'">
                  <div>
                    <multiselect
                      v-model="newVehicle[field.key]"
                      :options="field.values"
                      label="text"
                      track-by="value"
                      :allow-empty="false"
                      :multiple="false"
                      deselect-label=""
                      select-label=""
                    />
                  </div>
                </template>
                <template v-if="field.type === 'select-text-name'">
                  <div>
                    <multiselect
                      v-model="newVehicle[field.key]"
                      :options="field.values"
                      label="text"
                      track-by="name"
                      :allow-empty="false"
                      :multiple="false"
                      deselect-label=""
                      select-label=""
                    />
                  </div>
                </template>
                <template v-if="field.type === 'select-make'">
                  <multiselect
                    v-model="newVehicle[field.key]"
                    tag-placeholder="Add as a new make"
                    placeholder="Search or add a make"
                    :options="vehicleMakes"
                    :allow-empty="false"
                    :multiple="false"
                    :taggable="true"
                    deselect-label=""
                    select-label=""
                    @tag="addMake"
                    @changed="retrieveModels"
                    @input="retrieveModels"
                  />
                </template>
                <template v-if="field.type === 'select-model'">
                  <multiselect
                    v-model="newVehicle[field.key]"
                    tag-placeholder="Add as a new model"
                    placeholder="Search or add a model"
                    :options="vehicleModels"
                    :taggable="true"
                    track-by="value"
                    :multiple="false"
                    deselect-label=""
                    select-label=""
                    @tag="addModel"
                  />
                </template>

                <span>{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex justify-content-center pt-4">
          <b-button
            variant="primary"
            type="submit"
            aria-label="Continue to checkout button"
            :disabled="invalid || !loaded"
          >
            Continue to Checkout
          </b-button>
        </div>
      </validation-observer>
    </form>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: "VehicleRegistrationFormFields",
  components: {
    Multiselect,
  },
  props: {
    selectedRegistration: {
      type: Object,
      required: true,
    },
    oldVehicle: {
      type: Object,
      required: false,
    },
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      newVehicle: {
        vehicle_identification_number: '',
        model_year: '',
        make: '',
        model: '',
        msrp: '',
        color: '',
      },
      fields: [],
    }
  },
  computed: {
    ...mapGetters('vehicleRegistration', ['makes', 'vinInfo', 'models', 'vehicleSimpleProducts']),
    ...mapGetters('companies', ['currentCompany']),
    vehicleMakes() {
      return this.makes
    },
    vehicleModels() {
      return this.models
    },
    vinData() {
      return this.vinInfo
    },
    requireColor() {
      return sessionStorage.getItem('admin-logged-in') !== 'true'
    },
    defaultFields() {
      return [
        {
          key: 'vehicle_identification_number',
          label: 'VIN',
          type: 'vin',
          required: true,
        },
        {
          key: 'model_year',
          label: 'Model Year',
          type: 'number-lux',
          min: 1900,
          max: 3000,
          required: true,
        },
        {
          key: 'make',
          label: 'Make',
          type: 'select-make',
          required: true,
        },
        {
          key: 'model',
          label: 'Model',
          type: 'select-model',
          required: true,
        },
        {
          key: 'msrp',
          label: 'MSRP (Original Retail Price)',
          type: 'number-lux',
          min: 0,
          max: 100000000,
          required: true,
        },
        {
          key: 'color',
          label: 'Color',
          type: 'select',
          values: ['BEIGE', 'BLACK', 'BLUE', 'BROWN', 'BURGUNDY', 'CAMO', 'CREAM', 'GOLD', 'GREEN', 'GREY', 'MAROON', 'ORANGE', 'PINK', 'PURPLE', 'RED', 'SILVER', 'TAN', 'WHITE', 'YELLOW'],
          required: this.requireColor,
    },
      ]
    },
    lightVehicleFields() {
      return [
        {
          key: 'current_mileage',
          label: 'Current Mileage',
          type: 'number',
          min: 0,
          max: 1000000,
        },
        {
          key: 'luxury_tax',
          label: 'Vehicle Luxury Tax',
          type: 'select-luxury_tax',
          values: this.buildValues('luxury_tax'),
          required: true,
        },
        {
          key: 'import_fee',
          label: 'Was your vehicle purchased outside the USA?',
          type: 'select-electric-hybrid-tax',
          values: this.buildValues('import_fee'),
          required: true,
        },
        {
          key: 'registration_period',
          label: 'Registration Type',
          type: 'select',
          values: ['PERMANENT', 'ANNUAL'],
          required: true,
        },
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select-fuel-add-ev-hybrid-tax',
          values: ['GASOLINE', 'DIESEL', 'ELECTRIC', 'PLUG-IN HYBRID'],
          required: true,
        },
      ]
    },
    rvFields() {
      return [
        {
          key: 'rv_class',
          label: 'RV Class',
          type: 'select',
          values: ['Class A', 'Class B', 'Class C'],
        },
        {
          key: 'luxury_tax',
          label: 'Vehicle Luxury Tax',
          type: 'select-luxury_tax',
          values: this.buildValues('luxury_tax'),
        },
        {
          key: 'weight',
          label: 'Weight - GVWR (lbs)',
          type: 'number',
          min: 0,
          max: 100000,
          required: true,
        },
        {
          key: 'length',
          label: 'Length (ft)',
          type: 'number',
          min: 0,
          max: 100000,
        },
        {
          key: 'current_mileage',
          label: 'Current Mileage',
          type: 'number',
          min: 0,
          max: 1000000,
        },
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select-fuel-add-ev-hybrid-tax',
          values: ['GASOLINE', 'DIESEL', 'ELECTRIC', 'PLUG-IN HYBRID'],
          required: true,
        },
        {
          key: 'registration_period',
          label: 'Registration Type',
          type: 'select',
          values: ['PERMANENT', 'ANNUAL'],
          required: true,
        },
      ]
    },
    boatFields() {
      return [
        {
          key: 'length',
          label: 'Length (ft)',
          type: 'number',
          required: true,
        },
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select',
          values: ['GASOLINE', 'DIESEL', 'ELECTRIC', 'PLUG-IN HYBRID'],
          required: true,
        },
        {
          key: 'boat_propulsion',
          label: 'Boat Propulsion',
          type: 'select',
          values: ['Aux/Sail', 'Hand Prop', 'Inboard', 'Jet', 'Inb-Outb', 'Sail only', 'Other'],
          required: true,
        },
        {
          key: 'vessel_material',
          label: 'Vessel Material',
          type: 'select',
          values: ['Aluminum', 'Cement', 'Plastic/Fiberglass', 'Steel', 'Wood', 'Other'],
          required: true,
        },
        {
          key: 'vessel_type',
          label: 'Vessel Type',
          type: 'select',
          values: [
            'Amphibious', 'Cruiser', 'House Boat', 'Inflatable', 'Kayak/Canoe',
            'Pontoon', 'Row Boat', 'Runabout', 'Sailboat', 'Other',
          ],
          required: true,
        },
      ]
    },
    trailerTravelFields() {
      return [
        {
          key: 'weight',
          label: 'Weight - GVWR (lbs)',
          type: 'number',
          min: 0,
          max: 100000,
        },
        {
          key: 'length',
          label: 'Length (ft)',
          type: 'number',
          min: 0,
          max: 100000,
        },
      ]
    },
    cargoTrailerFields() {
      return [
        {
          key: 'weight',
          label: 'Weight - GVWR (lbs)',
          type: 'number',
          min: 0,
          max: 100000,
        },
        {
          key: 'length',
          label: 'Length (ft)',
          type: 'number',
          min: 0,
          max: 100000,
        },
      ]
    },
    heavyTruckFields() {
      return [
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select',
          values: ['GASOLINE', 'DIESEL', 'ELECTRIC', 'PLUG-IN HYBRID'],
          required: true,
        },
        {
          key: 'current_mileage',
          label: 'Current Mileage',
          type: 'number',
          min: 0,
          max: 1000000,
        },
        {
          key: 'weight',
          label: 'Weight - GVWR (lbs)',
          type: 'number-gvw',
          min: 0,
          max: 54000,
          required: true,
        },
      ]
    },
    motorcycleFields() {
      return [
        {
          key: 'engine_displacement',
          label: 'Displacement (CCs)',
          type: 'number',
          required: true,
        },
        {
          key: 'current_mileage',
          label: 'Current Mileage',
          type: 'number',
          min: 0,
          max: 1000000,
        },
        {
          key: 'fuel_type',
          label: 'Fuel Type',
          type: 'select',
          values: ['GASOLINE', 'DIESEL', 'ELECTRIC', 'PLUG-IN HYBRID'],
          required: true,
        },
        {
          key: 'vehicle_subtype',
          label: 'Motorcycle Type',
          type: 'select-text-name',
          values: [
            { text: 'Street Only', name: 'street_legal' },
            { text: 'Off-road Only', name: 'offroad' },
            { text: 'Street + Off-road', name: 'combo' },
          ],
        },
      ]
    },
  },
  mounted() {
    this.fields = this.formFields()
    this.newVehicle['company_id'] = this.currentCompany.id
    if (this.oldVehicle) {
      this.newVehicle = this.oldVehicle
    }
  },
  methods: {
    ...mapActions('vehicleRegistration', [
      'getMakes',
      'getModels',
      'getVinInfo',
    ]),
    async retrieveVinInfo () {
      this.newVehicle.vehicle_identification_number = this.newVehicle.vehicle_identification_number?.toUpperCase()
      const excludedVehicleTypes = ['boat', 'recreational_vehicle', 'trailer']
      if (excludedVehicleTypes.includes(this.newVehicle.vehicle_type)) return
      const vin_length = this.newVehicle.vehicle_identification_number.length
      if (vin_length > 10 && vin_length <= 17) {
        await this.getVinInfo({ vin: this.newVehicle.vehicle_identification_number })
        this.newVehicle = {
          ...this.newVehicle,
          make: this.vinData['Make']?.toUpperCase(),
          model: this.vinData['Model']?.toUpperCase(),
          model_year: this.vinData['ModelYear'],
          fuel_type: this.vinData['FuelTypePrimary']?.toUpperCase(),
        }
        this.determineEvHybridTax()
        this.determineRegistrationType()
        this.determineEvHybridClass()
      }
    },
    getMake() {
      this.getMakes({ vehicle_type: this.registrationType() })
    },
    addMake(make) {
      make = make.toUpperCase()
      this.makes.unshift(make)
      this.newVehicle.make = make
      this.getModels({ make: make })
    },
    addModel(model) {
      model = model.toUpperCase()
      this.models.unshift(model)
      this.newVehicle.model = model
    },
    retrieveModels() {
      this.newVehicle.make = this.newVehicle.make.toUpperCase()
      this.getMake()
      this.getModels({ make: this.newVehicle.make })
    },
    formFields() {
      this.newVehicle.vehicle_type = this.registrationType()
      this.getMakes({ vehicle_type: this.newVehicle.vehicle_type })
      const fields = this.defaultFields
      switch (this.newVehicle.vehicle_type) {
        case "boat":
          return fields.concat(this.boatFields).filter(field => field.key !== 'msrp')
        case "light_vehicle":
          return fields.concat(this.lightVehicleFields)
        case "heavy_truck":
          this.newVehicle.registration_period = 'ANNUAL'
          this.newVehicle.gvw_tax = this.buildValues('gvw_tax')?.at(-1)
          return fields.concat(this.heavyTruckFields)
        case "motorcycle":
          this.newVehicle.registration_period = 'PERMANENT'
          return fields.concat(this.motorcycleFields)
        case "recreational_vehicle":
          return fields.concat(this.rvFields)
        case "travel_trailer":
          this.newVehicle.vehicle_type = 'trailer'
          this.newVehicle.vehicle_subtype = 'travel'
          this.newVehicle.registration_period = 'PERMANENT'
          return fields.concat(this.trailerTravelFields)
        case "cargo_trailer":
          this.newVehicle.vehicle_type = 'trailer'
          this.newVehicle.vehicle_subtype = 'cargo'
          this.newVehicle.registration_period = 'PERMANENT'
          return fields.concat(this.cargoTrailerFields)
      }
      return fields
    },
    registrationType() {
      const selectedRegistrationName = this.selectedRegistration.name
      const registrationMapping = {
        "Boat": "boat",
        "Car/Truck": "light_vehicle",
        "Heavy Truck": "heavy_truck",
        "Motorcycle/ATV": "motorcycle",
        "Motor Home": "recreational_vehicle",
        "Travel Trailer": "travel_trailer",
        "Cargo Trailer": "cargo_trailer",
      }
      return registrationMapping[selectedRegistrationName] || null
    },
    formRules(field) {
      if (field.key === 'weight') {
        const required = field.required ? 'required|' : ''
        return `${required}weight:${this.newVehicle.vehicle_type}`
      }

      if (field.required) {
        return `required|${field.key}`
      }

      return null
    },
    handleSubmit() {
      this.$emit('new-vehicle', this.newVehicle)
    },
    buildValues(key) {
      const simpleProducts = this.vehicleSimpleProducts[this.registrationType()][key]
      let values = [{ text: 'No', value: false, add_to_invoice: false }]
      simpleProducts.forEach(product => {
        values.push({ text: `${product.name_override === 'Yes' ? 'Yes -  ' : ''}${product.name} + $${product.price}`.toUpperCase(), value: product.id, add_to_invoice: true, fields: product.fields })
        if (product['name_override']) {
          values.at(-1)['name_override'] = product['name_override'].toUpperCase()
        }
        if (product['field_override']) {
          values.at(-1)['field_override'] = product['field_override']
        }
      })
      return values
    },
    determineFixedValues() {
      this.determineLuxuryTax()
      this.determineRegistrationType()
    },
    addGVW() {
      if (this.newVehicle.gvw_tax?.fields) {
        this.newVehicle.gvw_tax.fields.data = { weight: this.newVehicle.weight }
      }
    },
    determineLuxuryTax() {
      const current_year = new Date().getFullYear()
      let lux_tax = null
      switch (this.newVehicle.vehicle_type) {
        case "light_vehicle":
          lux_tax = this.lightVehicleFields.find(item => item.key === 'luxury_tax').values
          this.newVehicle['luxury_tax'] = (this.newVehicle.msrp > 150000 && (current_year - this.newVehicle.model_year <= 10)) ? lux_tax[1] : lux_tax[0]
          break
        case "recreational_vehicle":
          lux_tax = this.rvFields.find(item => item.key === 'luxury_tax').values
          this.newVehicle['luxury_tax'] = (this.newVehicle.msrp > 300000 && (current_year - this.newVehicle.model_year <= 10)) ? lux_tax[1] : lux_tax[0]
          break
      }
    },
    determineRegistrationType() {
      const current_year = new Date().getFullYear()
      let registration_period = null
      switch (this.newVehicle.vehicle_type) {
        case "light_vehicle":
        case "recreational_vehicle":
          registration_period = this.fields.find(item => item.key === 'registration_period')
          if (current_year - this.newVehicle.model_year <= 11) {
            this.newVehicle['registration_period'] = registration_period.values[1]
            registration_period['disabled'] = true
          }
          else {
            this.newVehicle['registration_period'] = registration_period.values[0]
            registration_period['disabled'] = false
          }
          break
      }
    },
    determineEvHybridTax() {
      switch (this.newVehicle.vehicle_type) {
        case "light_vehicle":
        case "recreational_vehicle":
          delete this.newVehicle['electric_hybrid_tax']
          this.fields = this.fields.filter(field => field.key !== 'electric_hybrid_tax')
          if (this.newVehicle.fuel_type === 'ELECTRIC') {
            this.fields.push(
              {
                key: 'electric_hybrid_tax',
                label: 'Electric Vehicle Class',
                type: 'select-electric-hybrid-tax',
                values: this.buildValues('electric_hybrid_tax')
                            .filter(field => field.text.split(" ")[0] === 'ELECTRIC')
                            .map(field => {
                              field.text = field.text.replace(/^.*?(CLASS)/, "CLASS")
                              return field
                            }),
                required: true,
              }
            )
          }
          else if (this.newVehicle.fuel_type === 'PLUG-IN HYBRID') {
            this.fields.push(
              {
                key: 'electric_hybrid_tax',
                label: 'Plug-in Hybrid Class',
                type: 'select-electric-hybrid-tax',
                values: this.buildValues('electric_hybrid_tax')
                            .filter(field => field.text.split(" ")[0] === 'PLUG')
                            .map(field => {
                              field.text = field.text.replace(/^.*?(CLASS)/, "CLASS")
                              return field
                            }),
                required: true,
              }
            )
          }
          break
      }
    },
    determineEvHybridClass() {
      const evHybridIdentifiers = ['BEV (Battery Electric Vehicle)', 'PHEV (Plug-in Hybrid Electric Vehicle)']
      const evType = this.vinData['ElectrificationLevel']
      const isValidVehicleType = this.newVehicle.vehicle_type === 'light_vehicle'
      if (!evType || !evHybridIdentifiers.includes(evType) || !isValidVehicleType) return

      const evClass = this.vinData['GVWR'].match(/Class\s+\d+/)[0]?.toUpperCase()

      if (evType.includes('PHEV')) {
        this.newVehicle.fuel_type = 'PLUG-IN HYBRID'
        this.determineEvHybridTax()
      }

      const matchingClass = this.fields
        .find(field => field.key === 'electric_hybrid_tax')
        ?.values
        ?.find(value => value.text.includes(evClass))

      this.newVehicle['electric_hybrid_tax'] = matchingClass
    },
  },
}
</script>

<style lang="scss" scoped>
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25em;

  .form-item {
    width: 100%;

    .form-control {
      min-height: 43px !important;
    }

    .multiselect {
      margin-top: 0 !important;
    }
  }
}
@media (max-width: 736px) {
  .form-grid {
    grid-template-columns: 1fr;
  }
}
</style>
